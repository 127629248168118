import { Icon, Table } from '@platform-ui/design-system';
import { IADState } from '../../state';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Action, useStoreContext } from '../../../../Store';
import Connect from '../../../../Connect/Connect';
import { windmillRun } from '../../../../Connect/Connect';
import { MapOptions } from './MapOptions';
import { Field, MappedFieldValue } from './types';
import { useToast } from '../../../../ToastProvider';

interface FieldMappingTableProps {
  tabName: string;
  name: string;
  windmillPathSourcePath: string;
  windmillPathTargetPath: string;
  defaults?: Array<MappedFieldValue>;
  sourceTranslation: string;
  targetTranslation: string;
  manualInputOptionAllowed: boolean;
}

export const FieldMappingTable: FC<FieldMappingTableProps> = ({
  tabName,
  name,
  windmillPathSourcePath,
  windmillPathTargetPath,
  defaults,
  sourceTranslation,
  targetTranslation,
  manualInputOptionAllowed
}: FieldMappingTableProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const [filteredData, setFilteredData] = useState(state?.settingsHash?.[tabName]?.[name] ?? []);
  const { setToast } = useToast();
  const searchParams = new URLSearchParams(window.location.search);
  const activeTabName = searchParams.get('tabname');
  const isRowPending = (state.settingsHash[tabName]?.[name] || []).some(
    row => row.sourceField === '' || row.targetField === '' || row.sourceField?.value === '' || row.targetField?.value === ''
  );
  
  // Note: field mapping row format
  // [
  //   {
  //     sourceField: {
  //       label: 'Source Field Label',
  //       value: 'Source Field value',
  //       type: 'string'
  //     },
  //     targetField: {
  //       label: 'Target Field Label',
  //       value: 'Target Field value',
  //       type: 'any'
  //     },
  //     type: 'string',
  //     rowId: 0
  //   }
  // ]
  

  const addToMapping = (source: string, target: string, type: string) => {
    const currentMapping = state.settingsHash[tabName]?.[name] || [];
    // Find the highest existing rowId and add 1, or start at 0 if array is empty
    const nextRowId = currentMapping.length > 0 
      ? Math.max(...currentMapping.map(row => row.rowId)) + 1 
      : 0;

    dispatch({
      type: tabName,
      payload: {
        [name]: [
          ...currentMapping,
          { sourceField: source, targetField: target, type: type, rowId: nextRowId }
        ]
      }
    });
  }

  const deleteFromMapping = (rowId: number) => {
    const currentMapping = [...state.settingsHash[tabName][name]];
    const updatedMapping = currentMapping.filter(row => row.rowId !== rowId);
    
    dispatch({
      type: tabName,
      payload: {
        rowId,
        [name]: updatedMapping
      }
    });
  }
  
  const handleSearch = (searchValue: string) => {
    if (searchValue === '') {
      setFilteredData(state.settingsHash[tabName]?.[name] ?? []);
      return;
    }

    const rows: any[] = state.settingsHash[tabName]?.[name] || [];
    const searchValueUpper = searchValue.toUpperCase();

    setFilteredData(
      rows.filter(row => {
        const sourceLabel = row?.['sourceField']?.['label']?.toUpperCase();
        const targetLabel = row?.['targetField']?.['label']?.toUpperCase();
        return sourceLabel?.includes(searchValueUpper) || targetLabel?.includes(searchValueUpper);
      })
    );
  };

  const addFieldMapping = () => {
    addToMapping("", "", "string");
  };

  const addDefaultMappings = (defaults: Array<MappedFieldValue>) => {
    if (!defaults?.length) return;
  
    const defaultsWithIds = defaults.map((def, index) => {
      // Ensure source field has proper structure
      const sourceField = {
        type: def.sourceField.type || 'string',
        label: def.sourceField.label || String(def.sourceField.value),
        value: def.sourceField.value
      };
  
      // Ensure target field has proper structure
      const targetField = {
        type: def.targetField.type || 'string',
        label: def.targetField.label || String(def.targetField.value),
        value: def.targetField.value
      };
  
      return {
        ...def,
        rowId: index,
        type: def.type ?? sourceField.type, // Default to source field type if not specified
        sourceField,
        targetField
      };
    });
  
    dispatch({
      type: tabName,
      payload: {
        [name]: defaultsWithIds
      }
    });
  };
  


  const getFieldMappings = async () => {
    setLoading(true);
    try {
      // load source and target mappings
      const fetchedSourceOptions = await windmillRun({
        'script_path': windmillPathSourcePath,
        'params': { auth: { ...state.settingsHash['authentication'], envRestEndpoint: state.envRestEndpoint } },
        'async': false
      }, state.integrationAppId);
      
      setSourceOptions(fetchedSourceOptions || []);

      const fetchedTargetOptions = await windmillRun({
        'script_path': windmillPathTargetPath,
        'params': { auth: { ...state.settingsHash['authentication'], envRestEndpoint: state.envRestEndpoint } },
        'async': false
      }, state.integrationAppId);
      setTargetOptions(fetchedTargetOptions  || []);

    } catch (err) {
      Connect.log(err);
      setToast({     
        message: `Failed to load Mappings Options. Please double check credentials!`,
        severity: 'error',
        keyRender: Date.now()
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilteredData(state.settingsHash[tabName]?.[name] ?? []);
  }, [state.settingsHash[tabName]?.[name]]);

  useEffect(() => {
    if (activeTabName === tabName) {
      getFieldMappings();

      if (defaults) {
        // when default mappings are set in configuration,
        // if default mappings are not saved yet, display a toast why there are unsaved changes
        if (!state.settingsHash[tabName]?.[name]) {
          setToast({
            message: 'You must save default field mappings before proceeding.',
            severity: 'info',
            keyRender: Date.now()
          });
          addDefaultMappings(defaults);
        }
      }
    }
  }, [activeTabName]);

   
  const rowRenderer: any = (system: string, title: string, fieldOptions: Array<Field>) => {
    return {
      field: `${system}_fields`,
      label: `${title}`,
      dsRenderCell: ({ row }) => {
        return (
            <MapOptions fieldOptions={fieldOptions} row={row} system={system} mappingTableTab={tabName} mappingTableName={name} manualInputOptionAllowed={manualInputOptionAllowed}/>
        )
      }
    }
  };

  return (
    <>
      <Table
        loading={loading}
        columns={[
          rowRenderer("sourceField", sourceTranslation, sourceOptions),
          {
            field: '',
            label: '',
            alignment: 'center' as any,
            dsRenderCell: ({ row }) => {
              return (
                  <Icon
                    body="arrow_forward"
                  />
              )
            }
          },
          rowRenderer("targetField", targetTranslation, targetOptions),
        ]}
        uniqueKey={`${tabName}fieldmapping-field-table`}
        sortable={false}
        tableActions={[
          {
            icon: 'add',
            tooltip: isRowPending ? 'Set Pending Row First' : 'Add a Field Mapping',
            onClick: () => addFieldMapping(),
            disabled: isRowPending
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Field Mapping Sources',
            onClick: () => getFieldMappings()
          }
        ]}
        rowActions={[
          {
            icon: 'delete',
            tooltip: 'Delete a Field Map',
            onClick: (event) => deleteFromMapping((event as any).row.rowId)
          }
        ]}
        hidePagination={true}
        rowDisplayOptions={{
          hoverEffect: false
        }}
        searchable
        searchPlaceholder='Type field name to filter results...'
        dsOnSearchChange={(value) => handleSearch(value)}
        rows={filteredData}
      /> 
    </>
  );
};
